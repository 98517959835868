import { useCallback, useMemo } from 'react';
import axios from 'axios';
import axiosRetry from 'axios-retry';
import data2xml from 'data2xml';
import dropRight from 'lodash/dropRight';
import isObject from 'lodash/isObject';
import keys from 'lodash/keys';
import last from 'lodash/last';
import sortBy from 'lodash/sortBy';
import size from 'lodash/size';
import compact from 'lodash/compact';
import map from 'lodash/map';

import { FileSize } from '../../../../types';
import {
  S3MultipartFile,
  S3MultipartUploadFile,
  S3MultipartUploadOptions,
  S3MultipartUploadRequiredOptions
} from './useS3MultipartUpload.types';

import { CREATE_CLIENT_FILE_QUERY } from '../../../clientFiles/queries/createClientFile.query';
import { UPDATE_CLIENT_FILE_INFO_QUERY } from '../../../clientFiles/queries/updateClientFileInfo.query';
import { CREATE_FILE_ATTACHMENT_QUERY } from '../../../fileAttachments/queries/createFileAttachment.query';
import { UPDATE_FILE_ATTACHMENT_INFO_QUERY } from '../../../fileAttachments/queries/updateFileAttachmentInfo.query';
import { CREATE_IMAGE_QUERY } from '../../../images/queries/createImage.query';
import { UPDATE_IMAGE_INFO_QUERY } from '../../../images/queries/updateImageInfo.query';
import { CREATE_SOURCE_FILE_QUERY } from '../../../sourceFiles/queries/createSourceFile.query';
import { UPDATE_SOURCE_FILE_INFO_QUERY } from '../../../sourceFiles/queries/updateSourceFileInfo.query';
import { CREATE_MAX_FILE_QUERY } from '../../../maxFiles/queries/createMaxFile.query';
import { UPDATE_MAX_FILE_INFO_QUERY } from '../../../maxFiles/queries/updateMaxFileInfo.query';
import { CREATE_FILE_IMPORT_QUERY } from '../../../fileImports/queries/createFileImport.query';
import { UPDATE_FILE_IMPORT_INFO_QUERY } from '../../../fileImports/queries/updateFileImportInfo.query';

import { useCreateClientFile } from '../../../clientFiles/hooks/useCreateClientFile';
import { useUpdateClientFileInfo } from '../../../clientFiles/hooks/useUpdateClientFileInfo';
import { useCreateFileAttachment } from '../../../fileAttachments/hooks/useCreateFileAttachment';
import { useUpdateFileAttachmentInfo } from '../../../fileAttachments/hooks/useUpdateFileAttachmentInfo';
import { useCreateImage } from '../../../images/hooks/useCreateImage';
import { useUpdateImageInfo } from '../../../images/hooks/useUpdateImageInfo';
import { useCreateSourceFile } from '../../../sourceFiles/hooks/useCreateSourceFile';
import { useUpdateSourceFileInfo } from '../../../sourceFiles/hooks/useUpdateSourceFileInfo';
import { useCreateMaxFile } from '../../../maxFiles/hooks/useCreateMaxFile';
import { useUpdateMaxFileInfo } from '../../../maxFiles/hooks/useUpdateMaxFileInfo';
import { useCreateFileImport } from '../../../fileImports/hooks/useCreateFileImport';
import { useUpdateFileImportInfo } from '../../../fileImports/hooks/useUpdateFileImportInfo';
import { useCurrentUser } from '../../../../auth/hooks/useAuth';

import {
  ClientFileItemImageVersions,
  FileAttachmentItemImageVersions,
  ImageItemImageVersions
} from '../../../../helpers/ImageHelper';

import { Files } from '../../../../utils/Files';
import { fallbackS3Upload } from '../../utils/fallbackS3Upload';
import { generateNanoId } from '../../../../utils/generateNanoId';
import { slugifyFileName } from '../../../../utils/slugifyFileName';

import { CommonPermissions } from '../../../common/commonConstants';
import { AppPermissions } from '../../../../app/appConstants';

const createMutationTypes = {
  clientFiles: 'createClientFile',
  fileAttachments: 'createFileAttachment',
  images: 'createImage',
  sourceFiles: 'createSourceFile',
  maxFiles: 'createMaxFile',
  fileImports: 'createFileImport'
};

const asyncGeneratedVersions = {
  fileAttachments: [
    FileAttachmentItemImageVersions.Thumb120x120,
    FileAttachmentItemImageVersions.FullScreenThumb1000x850
  ],
  images: [
    ImageItemImageVersions.MiniThumb48x48,
    ImageItemImageVersions.MiniThumb96x96,
    ImageItemImageVersions.MiniThumb144x144,
    ImageItemImageVersions.Thumb150x150,
    ImageItemImageVersions.PdfThumb,
    ImageItemImageVersions.BigThumb,
    ImageItemImageVersions.FullScreenThumb1000x850,
    ImageItemImageVersions.FullScreenThumb
  ],
  sourceFiles: [],
  clientFiles: [
    ClientFileItemImageVersions.Thumb,
    ClientFileItemImageVersions.MiniThumb48x48,
    ClientFileItemImageVersions.MiniThumb96x96,
    ClientFileItemImageVersions.MiniThumb144x144
  ]
};

const versions = {
  fileAttachments: [
    FileAttachmentItemImageVersions.MiniThumb640x640,
    FileAttachmentItemImageVersions.BigThumb538x435,
    FileAttachmentItemImageVersions.BigThumb538x435Fit,
    FileAttachmentItemImageVersions.MiniThumb144x144
  ],
  images: [
    ImageItemImageVersions.MiniThumb320x320,
    ImageItemImageVersions.MiniThumb640x640,
    ImageItemImageVersions.MiniThumb960x960,
    ImageItemImageVersions.Thumb160x160,
    ImageItemImageVersions.BigThumb538x435
  ],
  sourceFiles: [],
  clientFiles: [
    ClientFileItemImageVersions.MiniThumb320x320,
    ClientFileItemImageVersions.MiniThumb640x640,
    ClientFileItemImageVersions.MiniThumb960x960
  ]
};

const FILE_CHUNK_SIZE = 524_288_000;
const BIG_IMAGE_FILE_SIZE = 41_943_040;

const convert = data2xml({ xmlDecl: false });

axiosRetry(axios, { retries: 20, retryDelay: axiosRetry.exponentialDelay });

function useS3MultipartUpload({
  type,
  dataParams,
  maxFiles,
  activeFilesCount,
  preventMaxFilesOverload,
  onFilesAccepted,
  onFileCreated,
  onUploadProgress,
  onFileUploaded,
  onFileFailed,
  onFinishUpload,
  onDownloadProgress
}: S3MultipartUploadOptions & S3MultipartUploadRequiredOptions) {
  const currentUser = useCurrentUser();

  const { createClientFile } = useCreateClientFile({
    query: CREATE_CLIENT_FILE_QUERY
  });

  const { updateClientFileInfo } = useUpdateClientFileInfo({
    query: UPDATE_CLIENT_FILE_INFO_QUERY
  });

  const { createFileAttachment } = useCreateFileAttachment({
    query: CREATE_FILE_ATTACHMENT_QUERY
  });

  const { updateFileAttachmentInfo } = useUpdateFileAttachmentInfo({
    query: UPDATE_FILE_ATTACHMENT_INFO_QUERY
  });

  const { createImage } = useCreateImage({
    query: CREATE_IMAGE_QUERY
  });

  const { updateImageInfo } = useUpdateImageInfo({
    query: UPDATE_IMAGE_INFO_QUERY
  });

  const { createSourceFile } = useCreateSourceFile({
    query: CREATE_SOURCE_FILE_QUERY
  });

  const { updateSourceFileInfo } = useUpdateSourceFileInfo({
    query: UPDATE_SOURCE_FILE_INFO_QUERY
  });

  const { createMaxFile } = useCreateMaxFile({
    query: CREATE_MAX_FILE_QUERY
  });

  const { updateMaxFileInfo } = useUpdateMaxFileInfo({
    query: UPDATE_MAX_FILE_INFO_QUERY
  });

  const { createFileImport } = useCreateFileImport({
    query: CREATE_FILE_IMPORT_QUERY
  });

  const { updateFileImportInfo } = useUpdateFileImportInfo({
    query: UPDATE_FILE_IMPORT_INFO_QUERY
  });

  const createActions = useMemo(
    () => ({
      clientFiles: createClientFile,
      fileAttachments: createFileAttachment,
      images: createImage,
      sourceFiles: createSourceFile,
      maxFiles: createMaxFile,
      fileImports: createFileImport
    }),
    [
      createClientFile,
      createFileAttachment,
      createImage,
      createSourceFile,
      createMaxFile,
      createFileImport
    ]
  );

  const updateActions = useMemo(
    () => ({
      clientFiles: updateClientFileInfo,
      fileAttachments: updateFileAttachmentInfo,
      images: updateImageInfo,
      sourceFiles: updateSourceFileInfo,
      maxFiles: updateMaxFileInfo,
      fileImports: updateFileImportInfo
    }),
    [
      updateClientFileInfo,
      updateFileAttachmentInfo,
      updateImageInfo,
      updateSourceFileInfo,
      updateMaxFileInfo,
      updateFileImportInfo
    ]
  );

  const updateActionsName = useMemo(
    () => ({
      clientFiles: 'updateClientFileInfo',
      fileAttachments: 'updateFileAttachmentInfo',
      images: 'updateImageInfo',
      sourceFiles: 'updateSourceFileInfo',
      maxFiles: 'updateMaxFileInfo',
      fileImports: 'updateFileImportInfo'
    }),
    []
  );

  const onUpload = useCallback<(acceptedFiles: File[]) => void>(
    (acceptedFiles) => {
      const expectedFilesCount = activeFilesCount + size(acceptedFiles);
      if (
        maxFiles &&
        preventMaxFilesOverload &&
        expectedFilesCount > maxFiles
      ) {
        return;
      }

      const files = sortBy<S3MultipartUploadFile & { acceptedFile: File }>(
        acceptedFiles.map((acceptedFile) => ({
          id: generateNanoId(),
          acceptedFile,
          name: acceptedFile.name,
          file: URL.createObjectURL(acceptedFile),
          size: acceptedFile.size,
          progresses: {},
          state: 'initialized' as const,
          isImage: Files.isImage(acceptedFile.name)
        })),
        (file) => file.name
      );

      onFilesAccepted?.(files);

      const fileUploads = files.map(
        (file) =>
          new Promise<S3MultipartFile>((resolve) => {
            const vars = {
              name: currentUser.hasPermissions(
                AppPermissions.CREATE_FILE_NAME_WITHOUT_SLUGIFY
              )
                ? file.name
                : slugifyFileName(file.name),
              originalFilename: file.name,
              size: file.acceptedFile.size as FileSize
            };

            if (isObject(dataParams)) {
              keys(dataParams).map((key) => (vars[key] = dataParams[key]));
            }

            createActions[type](vars)
              .then((res) => {
                const presignedUrls =
                  res?.[createMutationTypes[type]]?.record?.presignedUrls;
                const uploadedFile = file.acceptedFile;
                const promises = [];

                onFileCreated?.(file, presignedUrls);

                (size(presignedUrls) > 1
                  ? dropRight<string>(presignedUrls)
                  : presignedUrls
                ).map((presignedUrl, index) => {
                  const start = index * FILE_CHUNK_SIZE;
                  const end = (index + 1) * FILE_CHUNK_SIZE;
                  const blob =
                    index < presignedUrls.length
                      ? uploadedFile.slice(start, end)
                      : uploadedFile.slice(start);

                  promises.push(
                    axios.put(presignedUrl, blob, {
                      headers: {
                        'Content-Type': file.acceptedFile.type
                      },
                      onUploadProgress: (progressEvent) =>
                        onUploadProgress?.(
                          file.id,
                          index,
                          Math.round(
                            (progressEvent.loaded * 100) / progressEvent.total
                          )
                        )
                    })
                  );
                });

                return Promise.all(promises)
                  .then((parts) =>
                    size(presignedUrls) > 1
                      ? axios.post(
                          last(presignedUrls),
                          convert('CompleteMultipartUpload', {
                            _attr: {
                              xmlns: 'http://chilts.org/xml/namespace'
                            },
                            Part: parts.map((part) => ({
                              PartNumber:
                                presignedUrls.indexOf(part.config.url) + 1,
                              ETag: part.headers.etag.replace(/"/g, '')
                            }))
                          }),
                          {
                            headers: { 'Content-Type': 'text/xml' }
                          }
                        )
                      : null
                  )
                  .then(() =>
                    Promise.all([
                      file.isImage &&
                        size(versions[type]) > 0 &&
                        updateActions[type]({
                          uuid: res?.[createMutationTypes[type]]?.recordUuid,
                          versions: versions[type],
                          async:
                            Files.isGif(uploadedFile.name) ||
                            uploadedFile.size > BIG_IMAGE_FILE_SIZE
                        }).catch(async (error) => {
                          if (
                            !currentUser.hasPermissions(
                              CommonPermissions.READ_FALLBACK_S3_UPLOAD
                            )
                          ) {
                            throw error;
                          }

                          const uploadedFile = res?.[createMutationTypes[type]]
                            ?.record as S3MultipartFile;

                          await fallbackS3Upload(
                            uploadedFile.presignedUrl,
                            uploadedFile.name,
                            versions[type],
                            type
                          );
                        }),

                      file.isImage &&
                        size(asyncGeneratedVersions[type]) > 0 &&
                        updateActions[type]({
                          uuid: res?.[createMutationTypes[type]]?.recordUuid,
                          versions: asyncGeneratedVersions[type],
                          async: true
                        }).catch(async (error) => {
                          if (
                            !currentUser.hasPermissions(
                              CommonPermissions.READ_FALLBACK_S3_UPLOAD
                            )
                          ) {
                            throw error;
                          }

                          const uploadedFile = res?.[createMutationTypes[type]]
                            ?.record as S3MultipartFile;

                          await fallbackS3Upload(
                            uploadedFile.presignedUrl,
                            uploadedFile.name,
                            asyncGeneratedVersions[type],
                            type
                          );
                        }),

                      updateActions[type]({
                        uuid: res?.[createMutationTypes[type]]?.recordUuid,
                        async: true
                      })
                    ])
                      .then((updateRes) => {
                        const uploadedFile = res?.[createMutationTypes[type]]
                          ?.record as S3MultipartFile;

                        const updatedFile = compact(updateRes)[0]?.[
                          updateActionsName[type]
                        ]?.record?.file as string;

                        onFileUploaded?.(
                          file.id,
                          uploadedFile?.id,
                          updatedFile,
                          { ...uploadedFile, file: updatedFile }
                        );
                        resolve({ ...uploadedFile, file: updatedFile });
                      })
                      .catch(() => onFileFailed?.(file.id))
                  )
                  .catch(() => onFileFailed?.(file.id));
              })
              .catch(() => onFileFailed?.(file.id));
          })
      );

      return Promise.all(fileUploads).then((uploadedFiles: S3MultipartFile[]) =>
        onFinishUpload?.(map(uploadedFiles, 'id'), uploadedFiles)
      );
    },
    [
      activeFilesCount,
      maxFiles,
      preventMaxFilesOverload,
      onFilesAccepted,
      currentUser,
      dataParams,
      createActions,
      type,
      onFileCreated,
      onUploadProgress,
      updateActions,
      updateActionsName,
      onFileUploaded,
      onFileFailed,
      onFinishUpload
    ]
  );

  const onUploadFromRemoteUrl = useCallback<
    (filesWithUrl: S3MultipartFile[]) => void
  >(
    async (filesWithUrl) => {
      const files: File[] = [];

      const downloadFiles = filesWithUrl.map(async (fileWithUrl) => {
        await axios
          .get(fileWithUrl.file, {
            responseType: 'blob',
            onDownloadProgress: (progressEvent) =>
              onDownloadProgress?.(
                fileWithUrl.id,
                Math.round((progressEvent.loaded * 100) / progressEvent.total)
              )
          })
          .then((response) => {
            files.push(new File([response.data], fileWithUrl.name));
          })
          .catch(() => onFileFailed?.(fileWithUrl.id));
      });

      await Promise.all(downloadFiles).then(() => onUpload(files));
    },
    [onDownloadProgress, onFileFailed, onUpload]
  );

  return { onUpload, onUploadFromRemoteUrl };
}

export default useS3MultipartUpload;
